.menu {
    /* border: 1px solid seagreen; */
    position: absolute;
    z-index: 10;
    top: 0;

    width: 100%;
    height: 70px;
    padding: 0 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu .nombre {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.menu svg {
    cursor: pointer;
}

.menu .nombre svg {
    fill: var(--color-5);
    width: 30px;
    height: 30px;
    display: none;
}

.menu .nombre h2 {
    font-family: var(--texto-0);
    font-weight: 700;
    font-size: 24px;
}

.menu_lista {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.menu_lista li {
    font-size: 18px;
    cursor: pointer;
}

.menu_lista li a:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    text-underline-position: under;
    text-decoration-color: var(--color-5);
}

.menu_lista svg {
    display: none;
}

.lista_activar {
    top: 0;
}

@media screen and (max-width: 1440px) {
    .menu {
        padding: 0 20px;
    }
}

@media screen and (max-width: 1280px) {
    .menu_lista li {
        font-size: 16px;
    }

}

@media screen and (max-width: 960px) {
    .menu .nombre svg {
        display: block;
    }

    .menu_lista {
        position: fixed;
        top: -1000px;
        right: 0;

        display: flex;
        flex-direction: column;
        gap: 40px;

        width: 100%;
        height: 100vh;
        background: var(--color-5);
        color: var(--color-negro);
    }

    .menu_lista_activar {
        position: fixed;
        top: 0;
        right: 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 60px;

        width: 100%;
        height: 100vh;
        background: var(--color-5);
        color: var(--color-negro);
    }

    .menu_lista_activar svg {
        position: absolute;
        top: 20px;
        right: 20px;
        color: var(--color-5);
    }

    .menu_lista_activar li a {
        font-size: 32px;
        font-weight: 400;
        color: var(--color-negro);
        cursor: pointer;
    }
}

@media screen and (max-width:768px) {
    .menu .nombre h2 {
        font-size: 20px;
    }
}
.presentacion {
  /* border: 1px solid seagreen; */
  position: relative;

  width: 100%;
  height: 100vh;
  background: var(--color-negro);

  overflow: hidden;
}

.foto {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 10%;

  height: 100vh;
  mix-blend-mode: screen;
}

.foto img {
  height: 100%;
  mask-image: linear-gradient(black 90%, transparent);
}

.tarjeta {
  position: absolute;
  z-index: 2;
  top: 40%;
  left: 20%;

  width: 500px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.tarjeta_titulos h3 {
  font-size: 48px;
  color: var(--color-blanco);
  text-wrap: nowrap;
}

.tarjeta p {
  color: var(--color-negro-1);
}

.boton {
  background: var(--color-5);
  padding: 8px 20px;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.boton p {
  color: var(--color-negro);
  font-weight: 400;
}

.boton svg {
  width: 25px;
  height: auto;
  fill: var(--color-blanco);
  box-shadow: var(--sombra);
}

@media screen and (max-width: 1440px) {
  .foto {
    right: 0;
  }

  .tarjeta {
    left: 10%;
  }
}

@media screen and (max-width: 1200px) {
  .foto {
    right: 0;
  }

  .tarjeta {
    left: 5%;
  }
}

@media screen and (max-width: 960px) {
  .foto {
    top: 50%;
    left: 50%;
    transform: translate(-60%, -50%);
  }
}

@media screen and (max-width: 768px) {
  .tarjeta_titulos,
  p {
    width: 80%;
  }

  .tarjeta_titulos h3 {
    font-size: 36px;
  }

  .tarjeta p {
    font-size: 14px;
  }

  .boton {
    padding: 6px 20px;
    border-radius: 18px;
    gap: 5px;
  }

  .boton p {
    font-size: 16px;
    color: var(--color-negro);
    font-weight: 400;
  }
}

@media screen and (max-width: 576px) {
  .tarjeta {
    top: 33%;
    left: 50%;
    transform: translate(-50%);

    width: 90%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .tarjeta_titulos h3 {
    font-size: 32px;
  }
}

@media screen and (max-width: 576px) {
  .tarjeta_titulos h3 {
    font-size: 28px;
  }

  .tarjeta p {
    font-size: 13px;
  }

  .boton p {
    font-size: 14px;
  }
}

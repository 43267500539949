.contactame {
    background: var(--color-negro);

    width: 100%;
    padding: 90px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 120px;
}

.texto_contactame {
    width: 800px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.texto_contactame h3 {
    font-size: 48px;
    text-align: center;

    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 0;
    text-underline-position: under;
    text-decoration-color: var(--color-6);
    text-decoration-style: wavy;
}

.texto_contactame p {
    text-align: center;
}

@media screen and (max-width: 960px) {
    .texto_contactame {
        width: 95%;
    }
}

@media screen and (max-width: 768px) {
    .contactame {
        gap: 60px;
    }

    .texto_contactame h3 {
        font-size: 36px;
    }

    .texto_contactame p {
        font-size: 14px;
    }
}

.contacto {
    width: 1200px;

    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    gap: 30px;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.item h5 {
    cursor: pointer;
    font-weight: 300;
    font-size: 18px;
    color: var(--color-5);
}

.iconos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.iconos svg {
    fill: var(--color-5);
    width: auto;
    height: 40px;
    transition: fill ease-in-out 0.2s;
}

.iconos a:hover>svg {
    fill: var(--color-6);
}

@media screen and (max-width: 1280px) {
    .contacto {
        width: 90%;
    }
}

@media screen and (max-width: 960px) {
    .contacto {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 60px;
    }
}

@media screen and (max-width: 576px) {
    .contacto {
        gap: 30px;
    }
}
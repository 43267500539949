.formulario {
    position: fixed;
    z-index: 15;

    width: 100%;
    height: 100vh;
    background: var(--fondo-bg);
    color: var(--color-negro);

    display: flex;
    align-items: center;
    justify-content: center;
}

.formulario form {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    width: 400px;
    height: 540px;
    border-radius: 15px 90px 15px 15px;
    background: var(--color-blanco);
    box-shadow: var(--sombra);
}

.boton_cerrar {
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
}

.boton_cerrar svg {
    width: 40px;
    height: auto;
    fill: var(--color-blanco);
    transition: ease-in-out fill 0.2s;
}

.boton_cerrar svg:hover {
    fill: var(--color-7);
}

.contenedorInput {
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
}

.contenedorInput label {
    font-size: 14px;
}

.contenedorInput input {
    width: 100%;

    border: none;
    outline: none;
    padding: 12px 14px;
    border-radius: 15px;
    font-family: var(--texto-1);
    color: var(--color-negro-8);
    font-size: 16px;
    background: var(--color-negro-2);
}

.contenedorInput textarea {
    width: 100%;

    border: none;
    outline: none;
    padding: 12px 14px;
    border-radius: 15px;
    font-family: var(--texto-1);
    color: var(--color-negro-8);
    font-size: 16px;
    background: var(--color-negro-2);
    resize: none;
}

.contenedorInput textarea::-webkit-scrollbar {
    width: 8px;
}

.contenedorInput textarea::-webkit-scrollbar-track {
    background: var(--color-blanco);
}

.contenedorInput textarea::-webkit-scrollbar-thumb {
    background-color: var(--color-5);
    border-radius: 4px;
    border: 2px solid var(--color-blanco);
}

.formulario form button {
    width: 120px;
    height: 40px;

    border: none;
    background: var(--color-5);
    padding: 10px 25px;
    font-family: var(--texto-1);
    color: var(--color-negro-8);
    border-radius: 15px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}

.alerta {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.alerta .confirmacion {
    text-align: center;
    font-size: 14px;
    color: var(--color-6);
    font-weight: 400;
}

.alerta .error {
    text-align: center;
    font-size: 14px;
    color: var(--color-7);
    font-weight: 400;
}

@media screen and (max-width: 576px) {
    .formulario form {
        width: 90%;
        min-height: 500px;
        max-height: 600px;
    }
}
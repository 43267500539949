.experiencia {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 90px;
}

.texto_experiencia {
    width: 800px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.texto_experiencia h3 {
    font-size: 48px;
    text-align: center;

    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 0;
    text-underline-position: under;
    text-decoration-color: var(--color-6);
    text-decoration-style: wavy;
}

@media screen and (max-width: 1280px) {
    .experiencia {
        padding: 100px 0;
    }
}

@media screen and (max-width: 960px) {
    .texto_experiencia {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .experiencia {
        gap: 60px;
    }

    .texto_experiencia h3 {
        font-size: 36px;
    }
}

.grid_experiencia {
    width: 1200px;

    display: grid;
    grid-template-columns: 40% 20% 40%;
}

.grid_experiencia h4 {
    font-size: 24px;
}

.item_1 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 30px;
}

.linea {
    position: relative;

    width: 6px;
    background: var(--color-6);
    border-radius: 8px;
    margin: 0 auto;
}

.circulos {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 130px;
}

.linea_circulo {
    width: 20px;
    height: 20px;
    background: var(--color-5);
    border-radius: 50%;
}

.item_2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
}

.contenido_item_1 {
    min-height: 120px;
    text-align: end;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

.contenido_item_2 {
    min-height: 120px;
    text-align: start;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

@media screen and (max-width: 1280px) {
    .grid_experiencia {
        width: 90%;
        grid-template-columns: 45% 10% 45%;
    }
}

@media screen and (max-width: 768px) {
    .grid_experiencia {
        grid-template-columns: 42% 16% 42%;
    }

    .grid_experiencia h4 {
        font-size: 18px;
    }

    .grid_experiencia p {
        font-size: 14px;
    }
}
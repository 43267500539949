.app {
    position: relative;
}

.app_corte {
    position: relative;

    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.pantalla_carga {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    background: var(--color-negro);

    display: flex;
    align-items: center;
    justify-content: center;

}

.contenido_carga {
    width: 400px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.numero_carga {
    display: flex;
    align-items: center;
    justify-content: center;
}

.numero_carga h2 {
    width: 125px;
    text-align: end;
    font-size: 64px;
    animation: wave 1s ease-in-out infinite;
}

.numero_carga h3 {
    text-align: start;
    font-size: 48px;
    animation: wave 1s ease-in-out infinite;
}

.contenido_carga h1 {
    font-size: 28px;
    animation: wave 1s ease-in-out infinite;
}

.cargando {
    width: 100%;
    background: var(--color-negro-8);
    border-radius: 6px;
}

.progreso_barra {
    height: 6px;
    max-width: 100%;
    /* Evita que la barra se desborde */
    background-color: var(--color-5);
    border-radius: 6px;
    transition: width 0.2s;
}

@keyframes wave {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-8px);
    }

    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 576px) {
    .contenido_carga {
        width: 90%;
    }
}

.adornos {
    position: fixed;
    z-index: 5;
    top: 0;

    width: 100%;
    height: 100vh;
}

.boton_flotante {
    position: fixed;
    z-index: 4;
    bottom: 15px;
    right: 15px;

    cursor: pointer;
    padding: 15px;
    border-radius: 50%;
    background: var(--color-blanco);
    box-shadow: var(--sombra);
}

.boton_flotante svg {
    width: 40px;
    height: auto;
    fill: var(--color-5);
}

.boton_flotante:hover>svg {
    fill: var(--color-6);
}

@media screen and (max-width: 768px) {
    .boton_flotante {
        padding: 10px;
    }

    .boton_flotante svg {
        width: 35px;
    }
}
/* font-family: 'Syne', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');

/* font-family: 'Rubik', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* Quitar sombreado al hacer click */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:root {
  --color-blanco: #ffffff;
  --color-negro: #000000;

  --color-bg-foto: rgba(201, 201, 201, 0.7);

  --color-negro-0: #f5f5f5;
  --color-negro-1: #e5e5e5;
  --color-negro-2: #d4d4d4;
  --color-negro-3: #a3a3a3;
  --color-negro-4: #737373;
  --color-negro-5: #525252;
  --color-negro-6: #404040;
  --color-negro-7: #262626;
  --color-negro-8: #171717;
  --color-negro-9: #0a0a0a;

  --color-zinc-0: #fafafa;
  --color-zinc-1: #f4f4f5;
  --color-zinc-2: #e4e4e7;
  --color-zinc-3: #d4d4d8;
  --color-zinc-4: #a1a1aa;
  --color-zinc-5: #71717a;
  --color-zinc-6: #52525b;
  --color-zinc-7: #3f3f46;
  --color-zinc-8: #27272a;
  --color-zinc-9: #18181b;

  --color-5: rgb(136, 89, 245);
  --color-6: rgb(16, 244, 157);
  --color-7: #e70d0d;
  --color-8: #ffd11a;

  --sombra: 0 3px 5px rgba(0, 0, 0, 0.3);
  --sombra-svg: drop-shadow(0 3px 7px rgba(16, 244, 157, 0.4));
  --sombra-svg-hover: drop-shadow(0 3px 7px rgba(136, 89, 245, 0.4));

  --fondo-bg: rgba(20, 10, 30, 0.6);
  --fondo-foto: rgba(0, 0, 0, 0.8);

  --texto-0: 'Syne', sans-serif;
  --texto-1: 'Rubik', sans-serif;
}

body {
  color: var(--color-zinc-1);
  background: var(--color-negro);
  font-family: var(--texto-1);
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.05em;
}

a {
  text-decoration: none;
  color: var(--color-zinc-1);
  font-weight: 300;
}

li {
  list-style: none;
}

p {
  line-height: 1.5;
  font-weight: 300;
}

h1, h2, h3, h4, h5 {
  font-family: var(--texto-0);
  font-weight: 700;
}

.Typewriter__wrapper {
  color: var(--color-blanco);
  font-size: 48px;
  font-weight: 700;
  font-family: var(--texto-0);
}

.Typewriter__cursor {
  font-size: 48px;
  color: var(--color-5);
  font-weight: 700;
  font-family: var(--texto-0);
}

@media screen and (max-width: 768px) {
  .Typewriter__wrapper {
    font-size: 36px;
  }

  .Typewriter__cursor {
    font-size: 36px;
  }
}

@media screen and (max-width: 576px) {
  .Typewriter__wrapper {
    font-size: 32px;
  }

  .Typewriter__cursor {
    font-size: 32px;
  }
}

@media screen and (max-width: 460px) {
  .Typewriter__wrapper {
    font-size: 28px;
  }

  .Typewriter__cursor {
    font-size: 28px;
  }
}
.servicios {
    color: var(--color-blanco);

    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 140px;
}

.texto_servicios {
    width: 800px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.texto_servicios h3 {
    font-size: 48px;
    text-align: center;

    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 0;
    text-underline-position: under;
    text-decoration-color: var(--color-6);
    text-decoration-style: wavy;
}

.texto_servicios p {
    text-align: center;
    color: var(--color-negro-1);
}

.grid_servicios {
    width: 1200px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 80px;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
}

.icono {
    padding: 25px;
    border-radius: 50%;
    background: var(--color-5);
}

.icono svg {
    fill: var(--color-negro-8);
    width: auto;
    height: 55px;
}

.textos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.textos h5 {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.textos p {
    text-align: center;
    color: var(--color-negro-2);
    font-size: 14px;
}

@media screen and (max-width: 1280px) {
    .servicios {
        padding: 100px 0;
    }

    .grid_servicios {
        width: 80%;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 90px;
    }
}

@media screen and (max-width: 960px) {
    .texto_servicios {
        width: 95%;
    }

    .texto_cantidad {
        width: 90%;
    }

    .icono svg {
        fill: var(--color-negro-8);
        width: auto;
        height: 45px;
    }
}

@media screen and (max-width: 768px) {
    .servicios {
        gap: 90px;
    }

    .texto_servicios h3 {
        font-size: 36px;
    }

    .texto_servicios p {
        font-size: 14px;
    }

    .grid_servicios {
        grid-template-columns: repeat(1, 1fr);
        gap: 60px;
    }

    .textos p {
        font-size: 12px;
    }
}
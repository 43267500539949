.sobre_mi {
  position: relative;

  width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 100px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 120px;
}

.foto_botones {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.foto {
  width: 400px;
  border-radius: 50%;
  height: auto;
  aspect-ratio: 4/4;
  overflow: hidden;
  background: var(--color-5);
  border: 4px solid var(--color-5);
}

.foto img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.botones {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.boton_1 {
  background: var(--color-5);
  padding: 8px 20px;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
}

.boton_1 p {
  color: var(--color-negro);
  font-weight: 400;
}

.boton_2 {
  background: var(--color-5);
  padding: 8px 20px;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.boton_2 p {
  color: var(--color-negro);
  font-weight: 400;
}

.boton_2 svg {
  width: 25px;
  height: auto;
  fill: var(--color-blanco);
  box-shadow: var(--sombra);
}

.contenido {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.texto_sobre_mi {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
}

.texto_sobre_mi h3 {
  font-size: 48px;
  text-align: start;

  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 0;
  text-underline-position: under;
  text-decoration-color: var(--color-6);
  text-decoration-style: wavy;
}

.texto_sobre_mi p {
  text-align: start;
}

.flex_tecnologias {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.flex_tecnologias h4 {
  font-size: 20px;
  text-align: center;
}

.lista_iconos {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px;
}

.icono svg {
  fill: var(--color-5);
  width: auto;
  height: 35px;
  transition: fill ease-in-out 0.2s;
}

.icono svg:hover {
  fill: var(--color-6);
}

.texto_cantidad {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.texto_cantidad_contenido {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.texto_cantidad h3 {
  font-size: 48px;
}

.texto_cantidad h3 em {
  font-style: normal;
  color: var(--color-6);
}

.texto_cantidad p {
  text-align: center;
  font-size: 14px;
}

@media screen and (max-width: 1280px) {
  .sobre_mi {
    width: 100%;
    min-height: 100vh;
    padding: 100px 0;

    display: flex;
    flex-direction: column;
    gap: 90px;
  }

  .contenido {
    width: 900px;
  }

  .texto_sobre_mi {
    align-items: center;
  }

  .texto_sobre_mi h3 {
    text-align: center;
  }

  .texto_sobre_mi p {
    text-align: center;
  }

  .flex_tecnologias {
    width: 100%;
    align-items: center;
  }

  .lista_iconos {
    justify-content: center;
    gap: 30px;
  }

  .texto_cantidad {
    align-items: center;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 960px) {
  .contenido {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .sobre_mi {
    gap: 60px;
  }

  .texto_sobre_mi h3 {
    font-size: 36px;
  }

  .texto_sobre_mi p {
    font-size: 14px;
  }

  .texto_cantidad {
    gap: 20px;
  }

  .texto_cantidad h3 {
    font-size: 36px;
  }

  .texto_cantidad p {
    font-size: 12px;
  }

  .flex_tecnologias {
    gap: 20px;
  }

  .flex_tecnologias h4 {
    font-size: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .foto_botones {
    width: 90%;
    gap: 20px;
  }

  .foto {
    width: 80%;
  }

  .botones {
    width: 100%;
  }

  .boton_1 p {
    color: var(--color-negro);
    font-weight: 400;
    white-space: nowrap;
    font-size: 12px;
  }

  .boton_2 {
    gap: 5px;
  }

  .boton_2 p {
    font-size: 12px;
  }

  .icono svg {
    width: auto;
    height: 30px;
    transition: fill ease-in-out 0.2s;
  }
}

/* animaciones */

.bounce_top {
  -webkit-animation: bounce-top 0.9s both;
  animation: bounce-top 0.9s both;
}

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

.proyectos {
  width: 100%;
  min-height: 100vh;
  padding: 100px 0;
  color: var(--color-blanco);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 90px;
}

.texto_proyectos {
  width: 800px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.texto_proyectos h3 {
  font-size: 48px;
  text-align: center;

  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 0;
  text-underline-position: under;
  text-decoration-color: var(--color-6);
  text-decoration-style: wavy;
}

.texto_proyectos p {
  text-align: center;
  color: var(--color-negro);
  color: var(--color-negro-1);
}

@media screen and (max-width: 1280px) {
  .proyectos {
    padding: 100px 0;
  }
}

@media screen and (max-width: 960px) {
  .texto_proyectos {
    width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .proyectos {
    gap: 60px;
  }

  .texto_proyectos h3 {
    font-size: 36px;
  }

  .texto_proyectos p {
    font-size: 14px;
  }
}

.grid_proyecto {
  width: 1200px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 140px 90px;
}

.item_proyecto {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.foto {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 4/4.7;
  overflow: hidden;

  border-radius: 15px;
  background: var(--color-negro-8);
}

.foto img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.foto:hover > .lista_iconos,
.foto:hover > .fondo1,
.foto:hover > .fondo2 {
  bottom: 0;
  opacity: 1;
}

/* hover */

.lista_iconos {
  position: absolute;
  z-index: 2;
  bottom: -500px;

  width: 100%;
  height: 100%;
  padding: 40px 10px;
  transition: bottom 0.3s ease, opacity 0.6s ease;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 20px;
}

.detalles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.detalles h5 {
  font-size: 16px;
}

.detalles p {
  font-size: 14px;
  color: var(--color-blanco);
}

.contenido_lista {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}

.lista {
  width: 30px;
  height: 30px;
}

.lista svg {
  fill: var(--color-5);
  width: 100%;
  height: 100%;
  filter: var(--sombra-svg-hover);
  transition: fill ease-in-out 0.2s, filter ease-in-out 0.2s;
}

.lista svg:hover {
  fill: var(--color-6);
  filter: var(--sombra-svg);
}

.fondo1 {
  position: absolute;
  z-index: 1;
  bottom: -500px;

  width: 100%;
  height: 80%;
  transition: bottom 0.2s ease-in;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0) 100%
  );
}

.fondo2 {
  position: absolute;
  z-index: 1;
  bottom: -500px;

  width: 100%;
  height: 60%;
  transition: bottom 0.2s ease-in;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0) 100%
  );
}

/* modal foto */

.botones_hover {
  align-self: stretch;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 10px;
}

.botones_hover button {
  cursor: pointer;
  padding: 8px 20px;
  background: var(--color-6);
  border: none;
  border-radius: 14px;
  color: var(--color-negro);
  font-size: 12px;
  font-weight: 600;
  font-family: var(--texto-1);
}

.botones_hover a {
  cursor: pointer;
  padding: 8px 20px;
  background: var(--color-6);
  border: none;
  border-radius: 14px;
  color: var(--color-negro);
  font-size: 12px;
  font-weight: 600;
  font-family: var(--texto-1);
}

.modal_foto {
  position: relative;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_foto_url {
  position: relative;
  z-index: 9;
  height: 95%;
  overflow: hidden;
  border-radius: 15px;
  background: var(--color-blanco);
}

.modal_foto_url img {
  transform: translateY(25px);
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.modal_foto_url button {
  position: absolute;
  top: 5px;
  right: 5px;

  width: 30px;
  height: 30px;

  background: none;
  border: none;
  cursor: pointer;
}

.modal_foto_url button svg {
  fill: var(--color-5);
  width: 100%;
  height: 100%;
}

.bg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: var(--fondo-foto);
}

@media screen and (max-width: 960px) {
  .modal_foto_url {
    max-width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .modal_foto_url {
    max-width: 95%;
    height: auto;
  }

  .modal_foto_url img {
    transform: translateY(0);
  }

  .modal_foto_url button {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 576px) {
  .botones_hover button {
    font-size: 10px;
  }

  .botones_hover a {
    font-size: 10px;
  }
}

/*  */

.item_contenido {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.item_contenido h4 {
  font-size: 24px;
  color: var(--color-blanco);
  user-select: none;
}

.item_contenido p {
  user-select: none;
}

.color_tecnologias {
  color: var(--color-8);
}

.item_contenido em {
  color: var(--color-negro-1);
  font-weight: 500;
  font-style: normal;
}

.item_datos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}

.item_contenido a {
  color: var(--color-6);
  transition: color 0.2s ease-in;
}

.item_contenido a:hover {
  color: var(--color-5);
}

@media screen and (max-width: 1280px) {
  .grid_proyecto {
    width: 90%;
    gap: 120px 60px;
  }

  .fondo1 {
    height: 90%;
  }

  .fondo2 {
    height: 80%;
  }
}

@media screen and (max-width: 768px) {
  .grid_proyecto {
    grid-template-columns: repeat(1, 1fr);
  }

  .lista {
    width: 25px;
    height: 25px;
  }

  .fondo1 {
    height: 95%;
  }

  .fondo2 {
    height: 75%;
  }

  .item_contenido h4 {
    font-size: 20px;
  }

  .item_contenido p {
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  .grid_proyecto {
    gap: 90px 60px;
  }

  .lista_iconos {
    gap: 15px;
  }

  .detalles p {
    font-size: 12px;
  }

  .contenido_lista {
    gap: 20px;
  }
}
